import React, { useContext } from 'react'
import './index.css'
import { LanguageContext } from '../../../lang'

const BookingInfoSection = ({ data,footer, rideId, children }) => {
	const {
		custFullName, custEmail, custPhone, paymentType, destLoc, pickupLoc, totalFare, pickupDateForText, driverEarn
	} = data ? data : {}
	const {
		translations: { rideAccepted, customerDetail }
	} = useContext(LanguageContext)
	const {
		driverDetailsText,
		modelText,
		licenceText,
		colorText,
		nameText,
		phoneText,
		taxi
	} = rideAccepted;
	const { customer_info, name, email, phone, ride_details, pick_loc, drop_loc, ttl_fare, pay_mode, ride_date, driver_earn } = customerDetail;
	return (

			<div className='infoChildContainer'>
				{children}
				<div className='col-12 text-center mx-auto '>
					<span className='blueHeadings'>{`${customer_info}`}</span>
					<div className='detailsText'>
						<span>{`${name} : ${custFullName} `}</span>
					</div>

					<div className='detailsText2'>
						<span>{` ${email} : ${custEmail} `}</span>
					</div>
					<div className='detailsText2'>
						<span> {`${phone} : ${custPhone}`}</span>
					</div>
					<br />

				</div> 
				<div className='col-12 text-center mx-auto '>
					<h2 className='blueHeadings'>{ride_details}</h2>
					<div className='col-sm-12 detailsText'>
						<span>{`${pick_loc}: ${pickupLoc} `}</span>
					</div>

					<p className="detailsText">{`${drop_loc}: ${destLoc}`}</p>

					<p className="detailsText">{`${ride_date}: ${pickupDateForText}`}</p>

					<p className="detailsText">{paymentType !== 'onlinePayment' ? `${ttl_fare}: € ${totalFare}` : `${driver_earn}: € ${driverEarn}`}</p>

					
					{/* <p className="detailsText">{`${ttl_fare}: € ${totalFare}`}</p> */}

					<p className="detailsText">{`${pay_mode}: ${paymentType}`}</p>

				</div>
				{footer}
			</div>
	)
}

export default BookingInfoSection