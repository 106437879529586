import React, { Component } from "react";
import CustomerDetail from "../components/customer-detail";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import { isBrowser } from "../utils/general";

const CustomerDetails = () => {
  if (isBrowser()) {
    localStorage.clear();
  }
  return (
    <Layout>
      <SEO title="Customer-detail" />
      <CustomerDetail />
    </Layout>
  );
}; 

export default CustomerDetails;
